"use strict";

var _this = void 0;

var SF_OID_NEWSLETTER = '00DA0000000YzR4';
var SF_OID = '00DA0000000YzR4'; // live newsletter OID is 00DA0000000YzR4
// sandbox newsletter OID is 00D0r0000008gzk

jQuery(document).ready(function ($) {
  $('html').ajaxStart(function () {
    $(_this).addClass("wait");
  });
  $('html').ajaxStop(function () {
    $(_this).removeClass("wait");
  });
  var scrollPos = 0; //for restoring scrollPos after showing panes and hiding main
  //calculate heights of elements so we can use max-height trick for accordion

  var initAccordion = function initAccordion(el) {
    el.each(function (e) {
      var h;
      var $this = $(el[e]);
      $this.css({
        'overflow': 'visible',
        'max-height': 'none'
      });

      if ($this.is(':visible')) {
        h = $this.height();
      } else {
        $this.show();
        h = $this.height();
      }

      var extraPadding = 40; //fudge factor, because of padding and borders

      $this.css({
        'overflow': 'hidden',
        'max-height': 0
      });
      $this.attr('data-maxH', h + extraPadding);
    });
  }; //document.ready is too soon
  //$(window).on("load", () => {


  initAccordion($('.accordion')); //})

  $('.accordion_trigger').click(function (e) {
    var $this = $(e.currentTarget);
    var accordion_id = $this.attr('data-accordion');
    var $accordion = $('#' + accordion_id);

    if ($this.hasClass('unique')) {
      $(".accordion_trigger").not($this).each(function (i, el) {
        var $accordion = $('#' + $(el).attr('data-accordion'));
        $(el).removeClass('open');
        $accordion.removeClass('open');
        $accordion.css('max-height', 0);
      });
    }

    if ($this.hasClass('open')) {
      $('[data-accordion=' + accordion_id + ']').removeClass('open');
      $accordion.removeClass('open');
      $accordion.css('max-height', 0);
    } else {
      $('[data-accordion=' + accordion_id + ']').addClass('open');
      $accordion.addClass('open');
      $accordion.css('max-height', $accordion.attr('data-maxH') + 'px');
    }
  });
  /* HOME VIDEO */

  if ($(window).width() <= 1024) {
    $('body').removeClass('video');
  }

  $('#skip_intro').click(function () {
    $('body').removeClass('video'); //skip intro for one year

    document.cookie = 'skip_intro=1;max-age=' + 60 * 60 * 24 * 365 + '; path=/';
  });
  /* MENU: NARROW LAYOUT */

  var submenuHeightsCalculated = false; //add elements to menu because it's easier than using Wordpress's PHP

  $('#main_menu .menu_level_0>.sub-menu').wrap("<div class='submenu_holder'></div>"); //add title elements to each menu

  $('#main_menu .menu_level_0').each(function (i, e) {
    var title = $(e).children('a, span').text();
    $(e).children('.submenu_holder').prepend($("<h6 class='submenu_title'>" + title + "</h6>"));
  });
  $('#hamburger').click(function () {
    $('#main_menu').css({
      'transition': 'left .3s'
    });
    $('#menu_close').show();
    $('body').addClass('menu_shown');

    if (!submenuHeightsCalculated) {
      $('.menu_level_1').children('.sub-menu').each(function (i, e) {
        $(e).attr('data-maxH', $(e).height() + 21);
        $(e).css('max-height', 0);
      });
      submenuHeightsCalculated = true;
    }
  });
  $('#menu_close').click(function () {
    $('#menu_close, #menu_back').hide();
    $('body').removeClass('menu_shown');
    $('#main_menu').removeClass('page2');
  }); //add 'open' state for services menu

  $('#main_menu_services .menu_level_1').click(function (e) {
    if ($(window).width() >= 768) {
      return;
    }

    var $this = $($(e.currentTarget));
    var $sub = $this.find('.sub-menu');

    if ($this.hasClass('open')) {
      //disclosure triangle and background color are controlled by 2 separate classes, for timing
      $this.removeClass('open_arrow'); //wait .4 sec to remove CSS glitch

      setTimeout(function () {
        $this.removeClass('open');
      }, 400);
      $sub.css('max-height', 0);
    } else {
      //the above timing fuckery doesn't matter here because we're not hovered over the menu item while the animation occurs
      $('#main_menu_services .menu_level_1').removeClass('open open_arrow');
      $this.addClass('open open_arrow');
      $('#main_menu_services .menu_level_1 .sub-menu').css('max-height', 0);
      var maxH = $sub.attr('data-maxh') + 'px';
      $sub.css('max-height', maxH);
    }
  });
  $('li.menu_level_0>span').click(function (e) {
    var $this = $($(e.currentTarget).parent());

    if ($this.find('.sub-menu').length == 0) {
      return;
    }

    if ($(window).width() >= 768 && $this.attr('id') == 'main_menu_services') {
      return;
    }

    if ($(window).width() >= 768) {
      return;
    }

    $('.menu_level_0>.submenu_holder').hide();
    $this.children('.submenu_holder').show();
    $('#main_menu').addClass('page2');
    $('#menu_back').show();
  });
  $('#menu_back').click(function () {
    $('#main_menu').removeClass('page2');
    $('#menu_back').hide();
  });
  $(window).resize(function () {
    if ($(window).width() <= 1024) {
      $('body').removeClass('video');
    }

    if ($(window).width() < 768) {
      $('#main_menu').hide();
      $('.submenu_holder').css('margin-left', '');
    }

    $('#main_menu').show();
    $('#menu_close').click(); //Person layout relies on absolutely positioned element, so we have to set height with Javascript

    $('div.person_holder').css({
      'min-height': $('.person_content').height() - 40 + 'px'
    });
  }).resize();
  /* MENU: WIDE LAYOUT */

  $('.menu_level_0').mouseover(function (e) {
    if ($(window).width() < 768) {
      return;
    }

    var $this = $(e.currentTarget);
    $this.addClass('open'); //position the submenu

    var $submenu = $this.children('.submenu_holder');

    if ($this.offset().left + $submenu.outerWidth() > $(window).width()) {
      $submenu.css({
        'margin-left': $this.outerWidth() - $submenu.width() + 'px'
      });
    }

    $submenu.show();
  });
  $('.menu_level_0').mouseleave(function (e) {
    if ($(window).width() < 768) {
      return;
    }

    var $this = $(e.currentTarget);
    $this.removeClass('open');
  });
  /* ADD HOVER STATE TO SERVICES ON LANDING PAGE */

  if ($('body').hasClass('services')) {
    $('.service > a').mouseover(function (e) {
      var $this = $(e.currentTarget);
      $this.parent().addClass('over');
    });
    $('.service > a').mouseout(function (e) {
      var $this = $(e.currentTarget);
      $this.parent().removeClass('over');
    });
  }
  /*ADD HOVER STATE TO 'TALK TO ONE OF OUR SPECIALISTS */


  $('.talk .person_row').mouseover(function (e) {
    var $this = $(e.currentTarget);
    $this.addClass('over');
  });
  $('.talk .person_row').mouseout(function (e) {
    var $this = $(e.currentTarget);
    $this.removeClass('over');
  });
  /*CONTACT*/

  var initContactForm = function initContactForm() {
    $('form.contact_form').submit(function (e) {
      e.preventDefault();
      grecaptcha.execute();
      submitContactForm();
    });
    $('.contact-us .close_box').click(function (e) {
      /*
      if ($(e.currentTarget).attr('id') == 'menu_close') {
      	return
      }
      */
      $('#main').show();
      $('.contact-us').remove(); //restore scrollpos

      $(window).scrollTop(scrollPos);
    });
  };

  initContactForm(); //init this form wherever it may be, even if it's presented statically from the template php file

  /*PEOPLE*/

  if ($('body').hasClass('people')) {
    var $people = $('#people_list li');
    var newPeople,
        currentFilter = 'reset';
    $('ul.people_filter input[type=radio]').click(function (e) {
      $('#people_search').val('');
      var $this = $(e.currentTarget); //do nothing if current selection is clicked

      if ($this.attr('id') == currentFilter || $this.attr('id').indexOf('reset') > -1 && currentFilter == 'reset') {
        return;
      }

      var id = $this.attr('id');
      currentFilter = id;
      var title = $this.siblings('.option_title').html();

      if ($this.val() == 'reset') {
        //reset both filters
        $('input[value=reset]').prop('checked', true);
        currentFilter = 'reset';
        newPeople = $people;
      } else if ($this.parents('.people_filter').attr('id') == 'offices_list') {
        newPeople = $people.filter(function (i) {
          return $($people[i]).attr('data-office') == id;
        }); //uncheck other list

        $('#services_list input[type=radio]').prop("checked", false);
      } else {
        newPeople = $people.filter(function (i) {
          return $($people[i]).attr('data-services').split(' ').indexOf(id) >= 0;
        }); //uncheck other list

        $('#offices_list input[type=radio]').prop("checked", false);
      }

      var html = $('<div>').append(newPeople).html();
      $('#people_list').html(html);
      initPeopleJS();
      var res = newPeople.length;
      var msg;

      if (currentFilter == 'reset') {
        msg = 'Showing ' + res + ' results';
      } else if (res == 0) {
        msg = 'No results found';
      } else if (res == 1) {
        msg = 'Showing 1 result for <strong>' + title + '</strong>';
      } else {
        msg = 'Showing ' + res + ' results for <strong>' + title + '</strong>';
      }

      $('#results_title').html(msg).show();
      $('.accordion_trigger').removeClass('open');
      $('.accordion').css('max-height', 0).removeClass('open');
      $('.main').css({
        'min-height': $(window).height() - $('#footer').outerHeight() - parseInt($('.main').css('margin-top')) + 'px'
      });
    }); //allow linking from offices in footer

    if (window.location.hash) {
      $(window.location.hash).click();
    }

    $('#people_search').on("change keyup paste", function () {
      $('#services_list input[type=radio]').prop("checked", false);
      $('#offices_list input[type=radio]').prop("checked", false);
      var search = $('#people_search').val().toLowerCase();

      if (search) {
        newPeople = $people.filter(function (i) {
          return $($people[i]).find('h2').html().toLowerCase().indexOf(search) >= 0;
        });
        $('#results_title').hide();
        $('.accordion_trigger').removeClass('open');
        $('.accordion').css('max-height', 0).removeClass('open');
      } else {
        if ($('#offices_list input[type=radio]:checked').length == 0 && $('#services_list input[type=radio]:checked').length == 0) {
          newPeople = $people;
        }
      }

      var html = $('<div>').append(newPeople).html();
      $('#people_list').html(html);
      initPeopleJS();
    });
  }

  if ($('body').hasClass('person')) {
    $('div.person_holder').css('min-height', $('.person_content').height() + 100 + 'px');
  }

  $('#people_list li.person').mouseover(function (e) {
    var $this = $($(e.currentTarget).find('.find_out_more')[0]);
    $this.addClass('over');
  });
  $('#people_list li.person').mouseout(function (e) {
    var $this = $($(e.currentTarget).find('.find_out_more')[0]);
    $this.removeClass('over');
  });
  /*ALPHABET HELP MORE*/

  if ($('body').hasClass('examples-of-what-we-find')) {
    $('.box').click(function (e) {
      $('.box').removeClass('active');
      $('.block_description').hide();
      var $this = $(e.currentTarget).parent('.box_holder');
      var letter = $this.attr('id').substr(-1);
      var descriptionY = $this.offset().top + 16;
      $('#description-' + letter).show().css({
        top: descriptionY + 'px'
      });
    });
    $('.close_box').click(function (e) {
      var $this = $(e.currentTarget);
      $this.parents('.block_description').hide();
      $('.box').removeClass('active');
    });
  }
  /*BACKGROUND CHECK WIZARD*/


  if ($('body').hasClass('build-a-background-check')) {
    var preset = '',
        blockTitle,
        blockSubtitle;
    var presets = Array();
    presets['c-level-and-board'] = ['a', 'b', 'c', 'd', 'e', 'f', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'r', 'w'];
    presets['executive-with-financial-authority'] = ['a', 'b', 'c', 'd', 'e', 'f', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 's'];
    presets['manager'] = ['a', 'b', 'c', 'd', 'e', 'f', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o'];
    presets['associate'] = ['a', 'b', 'c', 'd', 'e', 'h', 'i', 'j', 'n', 'o'];
    presets['entry-level'] = ['a', 'b', 'h', 'i', 'j', 'n'];
    presets['final-commitment'] = ['b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'm', 'n', 'r', 'z'];
    presets['active-due-diligence'] = ['b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'm', 'n', 'r'];
    presets['initial-vetting'] = ['b', 'c', 'd', 'e', 'h', 'k', 'l', 'm', 'n'];
    presets['red-flags-only'] = ['d', 'e', 'f', 'g', 'n'];
    presets['high-risk'] = ['c', 'd', 'e', 'f', 'g', 'h', 'k', 'n', 'o', 'p', 'v', 'x', 'y'];
    presets['integrity-check'] = ['c', 'd', 'e', 'f', 'g', 'h', 'n', 'o', 'p', 'v'];
    presets['basic'] = ['f', 'g', 'h', 'o', 'v'];
    var boxesSelected = Array();

    var updateBoxesSelected = function updateBoxesSelected() {
      boxesSelected = $('.box.active');

      if (boxesSelected.length) {
        $('#get_quote_bt').addClass('active');
      } else {
        $('#get_quote_bt').removeClass('active');
      }
    };

    $('.box').click(function (e) {
      $(e.currentTarget).toggleClass('active');
      updateBoxesSelected();
      $('#best_practices_bt').removeClass('with_description');
    });
    $('#best_practices_bt').click(function () {
      preset = '';
      scrollPos = $(window).scrollTop();
      $('#best_practices_wizard').show();
      $('.main').hide();
      $(window).scrollTop(0);
      $('.accordion_trigger, .accordion').removeClass('open');
      $('h6').removeClass('dimmed');
      initAccordion($('.accordion'));
    });
    $('#best_practices_wizard .close_box').click(function () {
      $('#best_practices_wizard').hide();
      $('.main').show(); //restore scrollpos

      $(window).scrollTop(scrollPos);
    });
    $("input[name=before-transactions-number], input[name=before-third-parties-number]").click(function (e) {
      var $this = $(e.currentTarget);
      var $nextQ = $this.parents('.accordion').siblings('.accordion');
      $nextQ.css('max-height', $nextQ.attr('data-maxH') + 'px');
      $nextQ.addClass('open');
    });
    $('h6.accordion_trigger').click(function (e) {
      //close all the 2nd level questions too
      var $this = $(e.currentTarget);
      $('.accordion + .accordion').css('max-height', 0).removeClass('open');
      $("h6.accordion_trigger").removeClass('dimmed');
      $('.question_holder input[type=radio]').prop("checked", false);
      preset = '';
      $('#submit_bt').removeClass('active');

      if ($this.hasClass('open')) {
        $("h6.accordion_trigger").not($this).addClass('dimmed');
      }
    });
    $('input[name=before-hires], input[name=before-transactions-diligence], input[name=before-third-parties-diligence]').click(function (e) {
      var $this = $(e.currentTarget);
      preset = $this.val();
      $('#submit_bt').addClass('active');
    });
    $('#submit_bt').click(function () {
      if ($('#submit_bt').hasClass('active')) {
        $('#best_practices_wizard').hide();
        $('.box').removeClass('active');
        $('.main').show();
        $(window).scrollTop(scrollPos);

        for (var i = 0; i < presets[preset].length; i++) {
          $('#letter-' + presets[preset][i] + ' .box').addClass('active');
        }

        $('#best_practices_bt').addClass('with_description');
        blockTitle = $('#' + preset).closest('.practice').find('h6').text();
        blockSubtitle = $('#' + preset).siblings('.option_title').text();
        $('#best_practices_bt h5').text(blockTitle);
        $('#best_practices_bt h6').text(blockSubtitle);
        $('#get_quote_bt').addClass('active');
        updateBoxesSelected();
      }
    });
    /*GET QUOTE PANE*/

    $('#get_quote_bt').click(function () {
      if (!$('#get_quote_bt').hasClass('active')) {
        return;
      }

      scrollPos = $(window).scrollTop();
      $('#request_quote').show();
      $('.main').hide();
      $(window).scrollTop(0);
      $('#checks_list').empty();

      for (var i = 0; i < boxesSelected.length; i++) {
        var letter = $(boxesSelected[i]).parent().attr('id').substr(-1);
        $('<li/>', {}).html(blockObj[letter]).appendTo('#checks_list');
      }

      if (blockTitle) {
        $('#request_quote h5').text(blockTitle);
        $('#request_quote h6').text(blockSubtitle);
      }
    });

    var closeQuote = function closeQuote() {
      $('#request_quote').hide();
      $('.main').show(); //restore scrollpos

      $(window).scrollTop(scrollPos);
    };

    $('#request_quote .close_box').click(closeQuote);
    $('#request_quote_form form').submit(function (e) {
      e.preventDefault();
      submitContactForm();
    });
  }
  /* PARALLAX */


  if ($('#parallax_holder').length) {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      var parallaxH = $('#parallax_holder').innerHeight();
      var percentParallax = scroll / parallaxH * 100;

      if (percentParallax <= 100) {
        $('#parallax1').css({
          'background-position': 'center ' + percentParallax + '%'
        });
        $('#parallax2').css({
          'background-position': 'center ' + percentParallax * 0.75 + '%'
        });
        $('#parallax3').css({
          'background-position': 'center ' + percentParallax * 0.5 + '%'
        });
      }
    }).scroll();
  }
  /* 404 */
  //because there's no better way to determine the remaining height in the window


  if ($('body').hasClass('page_404')) {
    $(window).resize(function () {
      var extraH = $(window).height() - ($('#header').outerHeight() + $('#footer').outerHeight());
      $('#parallax_holder').css({
        height: extraH + 'px'
      });
    }).resize();
  }
  /*BLOG CATEGORY MENU*/
  //document.ready is too soon


  var catIndex = 0,
      catMenuW,
      offset = 30; //that's the padding-left on the ul

  var catMenuX = [];

  var displayArrows = function displayArrows() {
    if (catIndex == 0) {
      $('#cat_scroll_L').hide();
    } else if ($(window).width() < catMenuW) {
      $('#cat_scroll_L').show();
    }

    if (catMenuW - catMenuX[catIndex] < $(window).width()) {
      $('#cat_scroll_R').hide();
    } else {
      $('#cat_scroll_R').show();
    }
  };

  var initCatChooser = function initCatChooser() {
    catMenuX = [0];
    catMenuW = 0;
    $('#cat_chooser ul li').each(function (i, e) {
      catMenuW += $(e).outerWidth();
      catMenuX.push(catMenuW);

      if ($(e).find('span').length) {
        catIndex = i;
      }
    });
    $('#cat_chooser ul').css({
      width: catMenuW + offset + 'px'
    }); //scroll menu

    if (catMenuW > $(window).width()) {
      $('#cat_chooser ul').css({
        'margin-left': 0 - catMenuX[catIndex] + 'px'
      });
    } else {
      $('#cat_chooser ul').css({
        'margin-left': 0
      });
    }

    displayArrows();
  };

  $(window).on("load", function () {
    initCatChooser(); //add animation transitions

    $('#cat_chooser ul').css({
      'transition': 'margin-left .2s'
    });
  });

  if ($('body').hasClass('deep-background-blog')) {
    $(window).resize(function () {
      initCatChooser();
    });
    var hammertime = new Hammer(document.getElementById('cat_chooser'));
    hammertime.on('swipe', function (ev) {
      if (ev.overallVelocityX > 0) {
        if ($('#cat_scroll_L').is(':visible')) {
          $('#cat_scroll_L').click();
        }
      } else {
        if ($('#cat_scroll_R').is(':visible')) {
          $('#cat_scroll_R').click();
        }
      }
    });
  }

  $('.cat_scroll').click(function (e) {
    var $this = $(e.currentTarget);

    if ($this.attr('id') == 'cat_scroll_R') {
      catIndex++;
    } else {
      catIndex--;
    }

    $('#cat_chooser ul').css({
      'margin-left': 0 - catMenuX[catIndex] + 'px'
    });
    displayArrows();
  }); //reset email validation errors

  $('#email').on('input', function () {
    $('label[for="email"]').html('Your contact email').removeClass('error');
    $('#email').removeClass('error');
  }); // custom select, from https://www.w3schools.com/howto/howto_custom_select.asp

  var x, i, j, l, ll, selElmnt, a, b, c;
  /* Look for any elements with the class "custom_select_holder": */

  x = document.getElementsByClassName("custom_select_holder");
  l = x.length;

  for (var _i = 0; _i < l; _i++) {
    selElmnt = x[_i].getElementsByTagName("select")[0];
    ll = selElmnt.length;
    /* For each element, create a new DIV that will act as the selected item: */

    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;

    x[_i].appendChild(a);
    /* For each element, create a new DIV that will contain the option list: */


    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");

    for (var _j = 1; _j < ll; _j++) {
      /* For each option in the original select element,
      create a new DIV that will act as an option item: */
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[_j].innerHTML;
      c.addEventListener("click", function (e) {
        /* When an item is clicked, update the original select box,
        and the selected item: */
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;

        for (var _i2 = 0; _i2 < sl; _i2++) {
          if (s.options[_i2].innerHTML == this.innerHTML) {
            s.selectedIndex = _i2;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;

            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }

            this.setAttribute("class", "same-as-selected");
            break;
          }
        }

        h.click();
      });
      b.appendChild(c);
    }

    x[_i].appendChild(b);

    a.addEventListener("click", function (e) {
      /* When the select box is clicked, close any other select boxes,
      and open/close the current select box: */
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    /* A function that will close all select boxes in the document,
    except the current select box: */
    var x,
        y,
        i,
        xl,
        yl,
        arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    xl = x.length;
    yl = y.length;

    for (i = 0; i < yl; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }

    for (i = 0; i < xl; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  /* If the user clicks anywhere outside the select box,
  then close all select boxes: */


  document.addEventListener("click", closeAllSelect);
}); //end jQuery document ready

/*reCAPTCHA*/

/*
this is convoluted.
this is called on submit. It calls mn_sendMail via Ajax to Dilli verify email and send to people at Mintz. It adds more fields to form. It throws up a reCAPTCHA if necessary. If the ReCAPTCHA is successful, the form is submitted *again*, but this time it's actually submitted to SalesForce.
I'm not even sure if this is being done in the right order.
*/

var submitContactForm = function submitContactForm(token) {
  (function ($) {
    var $form = $('form.contact_form');

    if ($form.data('submitting') == true) {
      //remove submit handlers and submit form
      $form.off('submit');
      $form.submit();
    }

    if (!$('#contact_submit').hasClass('active')) {
      return;
    }

    var data = {};
    $('html').addClass("wait");
    $('#contact_submit').removeClass('active');
    data.f_name = $('#first_name').val();
    data.l_name = $('#last_name').val();
    data.email = $('#email').val();
    data.company = $('#company').val();
    data.phone = $('#phone').val();
    data.country = $('#country_code').val();
    data.msg = $('#description').val();
    data.submitted_to = $('#00NEX000000Ofzz').val();
    data.id = $('input[name=person_id]').val();

    if (data.id === undefined) {
      data.source = 'write_us'; // from Contact, too, if 'subscribe' box not checked
    } else {
      data.source = 'person';
    }

    if ($('#newsletter_subscribe').is(':checked') || $('body').hasClass('subscribe')) {
      data.subscribe = true;
    }

    data.content = "\nSomeone submitted a form on mintzgroup.com.\nFirst Name:\n".concat(data.f_name, "\n\nLast Name:\n").concat(data.l_name, "\n\nEmail:\n").concat(data.email, "\n\nCompany:\n").concat(data.company, "\n\nCountry:\n").concat(data.country, "\n\nMessage:\n").concat(data.msg, "\n\t\t\t");

    if (data.phone) {
      data.content += "\nPhone:\n".concat(data.phone, "\n\t\t\t");
    }

    if ($('#newsletter_subscribe').is(':checked')) {
      $('<input/>', {
        type: 'hidden',
        id: 'Signed_up_for_Newsletter__c',
        name: 'Signed_up_for_Newsletter__c',
        value: 1
      }).appendTo($form);
      data.content += "\nSubscribed to newsletter.\n\t\t\t";
    }

    if ($('body').hasClass('build-a-background-check')) {
      var interests = "";
      $('#checks_list li').each(function (i, e) {
        interests += $(e).html() + "\n";
      });
      data.source = 'request_quote';
      data.content += "\n\nThey are interested in:\n".concat(interests, "\n");
    }

    data.content += "\nSubmitted to:\n".concat(data.submitted_to, "\n\t\t");
    /* SF UTM Parameters */

    var parseGET = function parseGET(param) {
      var searchStr = document.location.search;

      try {
        var match = searchStr.match('[?&]' + param + '=([^&]+)');

        if (match) {
          var result = match[1];
          result = result.replace(/\+/g, '%20');
          result = decodeURIComponent(result);
          return result;
        } else {
          return '';
        }
      } catch (e) {
        return '';
      }
    };

    $('#utm_source').val(parseGET('utm_source'));
    $('#utm_medium').val(parseGET('utm_medium'));
    $('#utm_campaign').val(parseGET('utm_campaign'));
    $('#utm_term').val(parseGET('utm_term'));
    $('#utm_content').val(parseGET('utm_content'));
    $.ajax({
      type: "post",
      dataType: "json",
      url: phpvars.ajaxurl,
      data: {
        action: "mn_sendMail",
        data: data
      },
      success: function success(response) {
        if (response.result == true) {
          //for some reason SalesForce forms only work with .submit() and not with jQuery $.ajax. So the SF form submit has to be the last item in the chain of functions
          var oid;

          if (data.source == 'subscribe') {
            oid = SF_OID_NEWSLETTER;
          } else {
            oid = SF_OID;
          }

          $form.attr('action', 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8');
          $('<input/>', {
            type: 'hidden',
            name: 'oid',
            value: oid
          }).appendTo($form);
          /*
          					$('<input/>', {
          						type: 'hidden',
          						name: 'debug',
          						value: 1
          					}).appendTo($form)
          
          			
          					$('<input/>', {
          						type: 'hidden',
          						name: 'debugEmail',
          						value: 'megabulk@gmail.com'
          					}).appendTo($form)
          */
          //add flag so we can submit it again and handle it differently

          $form.data('submitting', true);

          if ($('#g-recaptcha-response').val()) {
            $form.submit();
          }

          return;
        } else if (response.result == 'invalid') {
          //WP email validation error
          $('label[for="email"]').html('Please enter a valid email address.').addClass('error');
          $('#email').addClass('error');
          grecaptcha.reset();
          $('#contact_submit').addClass('active');
          $('html').removeClass("wait");
        }
      }
    });
  })(jQuery);
};

var $c = function $c(t) {
  console.log(t);
};